// src/plugins/ladderStore.ts
import { defineStore } from 'pinia';

interface LadderState {
    ladderUID: string;
    ladderToken: string;
    ladderHWID: string;
    [key: string]: string; 
}

export const useLadderStore = defineStore('ladder', {
    state: (): LadderState => ({
        ladderHWID: '',
        ladderUID: '',
        ladderToken: '',
    }),
    actions: {
        setLadderHWID(uid: string) {
            this.ladderHWID = uid;
        },

        setLadderUID(uid: string) {
            this.ladderUID = uid;
        },
        setLadderToken(token: string) {
            this.ladderToken = token;
        },

        // 清除单个字段的方法
        clearLadderUID() {
            this.ladderUID = '';
        },
        clearLadderToken() {
            this.ladderToken = '';
        },
        clearLadderHWID() {
            this.ladderHWID = '';
        },

    },
    
});
