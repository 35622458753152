import { BaseArg, SuperSDK } from '../plugins/super';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useLadderStore } from '../plugins/ladderStore'
// 创建 SDK 实例

//const sdk = new SuperSDK("http://eicn.pih001.com:18888/")
const sdk = new SuperSDK()



let hwid = ""
let deviceJson = ""


// 定义 authService 对象
const authService = {
    //获取硬件id
    async getHwid() {
        const ladderStore = useLadderStore()
        // Get the visitor identifier when you need it.
        const fp = await FingerprintJS.load();
        const result = await fp.get()

        // This is the visitor identifier:
        const visitorId = result.visitorId

        // console.log(result, JSON.stringify(result.components), visitorId)tokenStr
        hwid = visitorId

        const device = result.components;

        // 检查device是否包含canvas属性，并且canvas有value属性，然后删除它

        if (device.canvas && typeof device.canvas === 'object' && 'value' in device.canvas) {
            // TypeScript 现在知道 device.canvas 是一个对象并且可能有一个 value 属性
            // 我们可以将这个属性设置为 undefined 或者直接操作它，而不是删除它
            // device.canvas.value = null;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete (<any>device.canvas).value;
        }
        deviceJson = JSON.stringify(device)
        // console.log("hwid: ", hwid, result.components)
        const oldhwid = ladderStore.ladderHWID || ''
        if (oldhwid != hwid) {
            console.log(`两次 Fingerprint 不一致, old = ${oldhwid}, new = ${hwid}`)
        }

        ladderStore.setLadderHWID(hwid)
        return hwid
    },

    // 获取登录二维码
    async getLoginQrCode() {
        return await sdk.user_createLoginQRCode({
            hwid: hwid,
            deviceJson: deviceJson,
            model: '',
            appVersion: '',
            systemName: '',
            identifier: '',
            currentName: '',
            systemVersion: '',
            idfv: '',
            description: ''
        });
    },

    // 检查二维码授权状态
    async checkAuthorize(authId: string) {
        return await sdk.user_checkAuthorize({ authId: authId } as BaseArg)
    },

    // 使用 Token 登录
    async loginByToken(token: string) {
        return await sdk.loginByToken(token)
    },
    async login() {
        return await sdk.user_login({
            hwid: hwid, deviceJson: deviceJson,
            model: '',
            appVersion: '',
            systemName: '',
            identifier: '',
            currentName: '',
            systemVersion: '',
            idfv: '',
            description: ''
        })
    },
    // 获取用户信息
    async getUserInfo() {
        return await sdk.user_getUserInfo({});
    },
};

export default authService;
