<template>
    <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true"
        ref="payRef">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="paymentModalLabel">{{ $t("message.place_order") }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="关闭">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="payment-element">
                    </div>


                    <button class="btn  btn-lg btn-block payment-button"
                        :class="['btn', isLoading ? 'btn-secondary' : 'btn-primary']" :disabled="isLoading"
                        @click="handleSubmit">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>
                        {{ isLoading ? $t("message.processing") : $t("message.place_order") + " US$" + (payInfo?.amount)
                        }}
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">


import { PayInfo } from "../../types/types"
import { ref, onMounted, onUnmounted } from 'vue';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Modal } from 'bootstrap';

const isLoading = ref(false);
const payInfo = ref<PayInfo | null>(null);
const payRef = ref<HTMLElement | null>(null);
let modalInstance: Modal | null = null;
let elements: StripeElements;
let stripeInstance: Stripe;

onMounted(() => {
    modalInstance = new Modal(payRef.value!, { keyboard: false });
});

onUnmounted(() => {
    modalInstance?.dispose();
});

async function initStripe() {
    if (!payInfo.value) return;
    try {
        loadStripe.setLoadParameters({ advancedFraudSignals: false });
        const stripe = await loadStripe(payInfo.value.publishableKey);
        if (!stripe) throw new Error('Stripe 加载失败');
        stripeInstance = stripe;
        elements = stripe.elements({ clientSecret: payInfo.value.clientSecret, appearance: { theme: 'stripe' } });
        const paymentElement = elements.create('payment', { layout: "tabs" });
        paymentElement.mount("#payment-element");
    } catch (error) {
        console.error(error);
    }
}

const showModal = async (info: PayInfo) => {
    payInfo.value = info;
    await initStripe();
    modalInstance?.show();
};



async function handleSubmit() {

    if (isLoading.value) {
        return
    }
    isLoading.value = true;
    const currentPageUrl: string = window.location.href;
    try {

        const result = await stripeInstance.confirmPayment({
            elements,
            confirmParams: {
                return_url: currentPageUrl,
            },
        });
        modalInstance?.hide();
        // cancelLoop()
        // 处理支付确认结果
        console.log("result: ", result)
        if (result.error) {
            isLoading.value = false;
        }
    } catch (error) {
        console.error(error);
        // 处理错误
    } finally {
        isLoading.value = false;
    }
}

const hideModal = () => modalInstance?.hide();

defineExpose({ showModal, hideModal });
</script>

<style scoped>
.payment-button {
    margin-top: 20px;
}
</style>