export default {
  message: {
    language: "語言",
    zh_hk: "繁體中文",
    en: "英文",
    home: '首頁',
    download: '下載',
    purchase: '購買',
    contact: '聯繫我們',
    customer: '客服支持',
    login: "登入",
    logout: "退出",
    enjoy: "享受一個自由的互聯網世界！",
    enjoy_title: "Hiven 暢連雲為您提供了一個理想的解決方案,可以打破網絡限制,讓您享受免費、開放的互聯網。使用高速優質的網絡雲及VPN服務,無論身在何處,您都可以輕鬆連結世界各地的站點,享受無縫的互聯網體驗。為什麼不現在就拿這個工具？立即使用,無限制地探索網絡！",
    choose_platform: "選擇您的設備平臺",
    check_out: "查看我們的暢連雲VPN服務",
    faster_safer: "更快更安全的網絡雲VPN服務",
    trial: "嘗鮮",
    member: "會員",
    ticket: "會員券",
    trial_vip: "嘗鮮",
    days: "天",
    sheets: "張",
    monthly_vip: "月費會員",
    quarterly_vip: "季費會員",
    yearly_vip: "年費會員",
    unlimited_bandwidth: "無限頻寬",
    emali_support: "全天候電子郵件支持",
    customer_support: "24小時客服：",
    product_desc: "暢連雲VPN服務是Hiven香港有限公司引以為傲的頂級產品。",
    quick_links: "快速連結",
    contact_info: "聯繫方式",
    ticket_count: "會員券: ",
    vaild_date: "有效期至: ",
    expired_on: "過期時間: ",
    be_member: "歡迎，現在就去成為會員吧",
    scan_code: "掃描下麵的二維碼登入網頁",
    expiration_time: "過期時間:",
    refresh: "刷新",
    cancel_login: "取消登錄",
    place_order: "提交訂單",
    processing: "處理中…",
    windows_download_tip:"如果該檔案 ladder.exe 運作時被微軟防火牆移除（新的應用程式常見問題），可開啟防火牆，查看移除記錄，手動授權使用該程式。",
    close:"關閉",
    download_tip:"下載提示"
  },
};
