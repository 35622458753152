import { useI18n } from 'vue-i18n'
import { ref, Ref } from "vue";
import { Goods, PayInfo } from '@/types/types';
import { format } from "date-fns";

import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { SuperSDK, type BaseArg } from './super';
import authService from "../services/authService"
import { intercomService } from '@/plugins/intercomService';
import { useLadderStore } from '@/plugins/ladderStore';
import Cookies from 'js-cookie';
export function home() {
    // const sdk = new SuperSDK("http://eicn.pih001.com:18888/")
    const sdk = new SuperSDK
    //loading
    const isLoading = ref(true);

    //国际化
    //menu
    const isMenuVisible = ref(false);
    const { t, locale } = useI18n()

    const ladderStore = useLadderStore()


    //Goods
    const ticketCount = ref("");
    const vipTime = ref("");
    const goodsIndex = ref(0)
    const currentList: Ref<Goods[]> = ref([]);
    const goodsList = ref({
        trial: [] as Goods[],
        member: [] as Goods[],
        ticket: [] as Goods[]
    });
    const selectIds = ref(["", "", ""])
    //登录
    const isShowLoginView = ref(false);
    const isOrderPlace = ref(false)
    //登录
    let token = ""
    let uid = ""

    let isCheckingAuth = true;
    //二维码
    const qrLink = ref("aa")
    const expirationTime = ref("aaa")
    //客户端检查
    const isAndroid = ref(false);
    const isIOS = ref(false);
    const isOther = ref(true);

    //下载地址与邮箱
    let androidDownloadUrl = ref("")
    let iosDownloadUrl = ref("")
    let windowsDownloadUrl = ref("")

    let email = ref("")

    const payRef = ref<{ showModal: (payInfo: PayInfo) => void }>();


    const downloadRef = ref<{ showModal: () => void }>();


    async function getHwid() {
        await authService.getHwid()

    }


    function switchLocale(language: string) {
        locale.value = language;

    }


    function toggleMenu() {
        isMenuVisible.value = !isMenuVisible.value;
        document.body.classList.toggle('over_hid', isMenuVisible.value);

    }


    function cancelLogin() {
        isCheckingAuth = false
        isShowLoginView.value = false
    }

    function getLocalTips(goods: Goods): string {
        return (locale.value == "zh-hk" ? goods.message_ch : goods.message_en) || ""
    }
    function setFacebookCookies() {
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');

        // 如果URL中包含fbclid，则设置_fbc
        if (fbclid) {
            const fbcValue = `fb.1.${Date.now()}.${fbclid}`;
            Cookies.set('_fbc', fbcValue, { expires: 90, path: '/' });
        }

        // 检查并可能设置_fbp
        if (!Cookies.get('_fbp')) {
            const fbpValue = `fb.1.${Date.now()}.${Math.floor(Math.random() * 1000000000)}`;
            Cookies.set('_fbp', fbpValue, { expires: 180, path: '/' });
        }
    }
    async function getPackages() {
        console.log("getPackages: start")
    
        const [, , obj] = await sdk.order_getPackages({ type: 1 } as BaseArg)
        console.log("getPackages:", obj)
        isLoading.value = false;
        goodsList.value.trial = obj.trial
        goodsList.value.ticket = obj.ticket
        goodsList.value.member = obj.member
        selectIds.value[0] = obj.trial[0].productId;
        selectIds.value[1] = obj.member[0].productId;
        selectIds.value[2] = obj.ticket[0].productId;
        console.log("selectIds: ", selectIds.value)
        updateCurrentList('member');
        isLoading.value = false

    }
    function updateCurrentList(type: 'trial' | 'member' | 'ticket') {
        const typeToIndexMap: { [key: string]: number } = {
            'trial': 0,
            'member': 1,
            'ticket': 2
        };
        goodsIndex.value = typeToIndexMap[type];
        // 更新 currentList
        currentList.value = goodsList.value[type];
    }

    //用户信息
    async function getUserInfo() {
        console.log("sdk.getUserInfo")
        const [code, msg, obj] = await sdk.user_getUserInfo({})
        if (obj == null) {
            return
        }
        intercomService.setUser(uid, obj.email)
        ticketCount.value = `${obj.ticket1 || "0"}`;
        switch (obj.userVipStatus) {
            case 0:
                vipTime.value = ""
                break
            case 2:
                vipTime.value = format(new Date(obj.expirationTime * 1000), "yyyy-MM-dd");
                break
            case 3:
                vipTime.value = format(new Date(obj.expirationTime * 1000), "yyyy-MM-dd");
                break
        }
    }

    function isLogined() {
        if (uid != '' && token != '') {
            return true;
        }
        return false;
    }

    async function checkAndLogin() {
        // 如果有token，用token登录
        intercomService.setUser()
        console.log("checkAndLogin")
        const tokenStr = ladderStore.ladderToken || ''

        if (tokenStr == null || tokenStr == '') {
            console.log("无token")
            return
        }
        console.log("自动登录:start")
        const [code, , obj] = await sdk.loginByToken(tokenStr)
        console.log("自动登录:end")
        if (code == 0) {
            uid = obj.uid
            token = tokenStr
            ladderStore.setLadderToken(token)
            ladderStore.setLadderUID(uid)
        } else {
            // 登录失败
            loginFailed()
        }
    }



    async function loginOrLogout() {
        if (!isLogined()) {

            await getLoginQrCode()
        }
        else {

            ladderStore.clearLadderToken()
            ladderStore.clearLadderUID()
            intercomService.logout()
            window.location.reload()
        }
    }

    function loginFailed() {
        ladderStore.clearLadderToken()
        ladderStore.clearLadderUID()
        uid = ''
        token = ''
    }

    async function getLoginQrCode() {
        console.log("getLoginQrCode")
        // let [code, msg, obj] = await sdk.user_createLoginQRCode({ hwid: hwid, deviceJson: "{}" })   // 这里先不传入 deviceJson, 以免调试麻烦
        const [, , obj] = await authService.getLoginQrCode()
        const authId = obj.authId
        qrLink.value = "ladder://login@" + authId
        expirationTime.value = format(new Date(obj.expirationTime * 1000), "yyyy-MM-dd HH:mm");
        isShowLoginView.value = true
        console.log("")
        isCheckingAuth = true;
        // 检查二维码
        const do_loop_checkAuth = async () => {
            if (!isCheckingAuth) return;
            const [code, , obj] = await sdk.user_checkAuthorize({ authId: authId } as BaseArg)
            console.log("checkAuthorize", code, obj)
            let bContinue = false;
            if (code == 0) {
                // 成功
                bContinue = false
                uid = obj.uid
                token = obj.token
                bContinue = false


                ladderStore.setLadderUID(uid);
                ladderStore.setLadderToken(token);
                //await authService.loginByToken(token);
                console.log("getUserInfo")
                await checkAndLogin()
                isShowLoginView.value = false
                getUserInfo()

            } else if (code == 1041) {
                // 等待，循环下去，其他都终止循环
                if (isCheckingAuth) { // 再次检查控制变量
                    setTimeout(do_loop_checkAuth, 10 * 1000);
                }

                bContinue = true
            } else {
                bContinue = false
                // 根据code 错误，是否提示（比如过期了
                // AUTH_WAITING = 1041,        // 等待扫码
                // QRCODE_ERROR = 1037,
                // QRCODE_NOT_EXISTS = 1032,
                // QRCODE_EXPIRED = 1034,
                // ErrorCode.ERR = 1001, "check auth 未知错误，有uid，但是没有token", { uid: auth.uid }
            }

            if (bContinue == false) {
                // 退出循环，取消订阅
                sdk.unsubscribeMessage("user", authId, onAuthCallback)
            }
        }
        // 回调消息
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const onAuthCallback = async () => {
            // 立刻执行回调
            do_loop_checkAuth()
        }

        // 订阅消息
        sdk.subscribeMessage("user", authId, onAuthCallback)

        // console.log("开始循环check")
        do_loop_checkAuth()
    }


    // 点击购买

    async function toBuyGoodsByButton(goods: Goods) {
        if (!isLogined()) {
            // 先登录，再点击购买
            getLoginQrCode()
            return
        }


        if (isOrderPlace.value) {
            return
        }
        goods.isLoading = true;
        isOrderPlace.value = true;
        const [, , obj] = await sdk.order_buildPayIntent({ pid: goods.productId, quantity: 1, payment_method: "", amount: (goods.price * 100), appstore: "web", inInfo: "stripe", currency: "hkd" })
        if (obj != null) {
            const payInfo: PayInfo = { publishableKey: obj.publishableKey, clientSecret: obj.clientSecret, amount: goods.price }
            payRef.value?.showModal(payInfo);
            isOrderPlace.value = false
            goods.isLoading = false
            // initStripe(obj.publishableKey, obj.paymentIntent)

        }
        else {
            goods.isLoading = false;
            isOrderPlace.value = false
        }

    }

    // async function initStripe(publishableKey: string, clientSecret: string) {
    //     loadStripe.setLoadParameters({ advancedFraudSignals: false });
    //     const stripePromise = loadStripe(publishableKey);
    //     stripePromise.then(async (stripe) => {
    //         if (stripe === null) {
    //             console.error('Stripe 加载失败');
    //             return;
    //         }


    //         const appearance: Appearance = {
    //             theme: 'stripe',
    //         };

    //         const elements = stripe.elements({ appearance, clientSecret });

    //         const paymentElementOptions:StripePaymentElementOptions = {
    //             layout: "tabs",
    //         };
    //         const paymentElement = elements.create( 'payment',paymentElementOptions);
    //         paymentElement.mount("#payment-element");
    //         await stripe.confirmPayment({
    //             elements,
    //             confirmParams: {
    //                 // Make sure to change this to your payment completion page
    //                 return_url: "http://localhost:4242/checkout.html",
    //             },
    //         });

    //         // stripe.confirmWechatPayPayment(
    //         //     paymentIntent,
    //         //     {
    //         //         payment_method_options: {
    //         //             wechat_pay: {
    //         //                 client: 'web',
    //         //             },
    //         //         }
    //         //     },
    //         // ).then(function ({ error, paymentIntent }) {
    //         //     if (error) {
    //         //         console.log("error", error);
    //         //     } else if (paymentIntent.status === 'succeeded') {
    //         //         getUserInfo()g
    //         //     } else if (paymentIntent.status === 'requires_action') {
    //         //         console.log("requires_action");
    //         //     }
    //         // });
    //     });
    // }


    async function getResourceUrls() {

        const [, , obj] = await sdk.user_getResourceUrls({})
        if (obj != null) {
            console.log("getResourceUrls: ", obj.email)
            email.value = obj.email
            const fbc = Cookies.get('_fbc');
            const fbp = Cookies.get('_fbp');
            console.log('Sending event with', fbc, fbp);
            const redirectUrlAndroid = encodeURIComponent(obj.androidDownloadUrl);
            const redirectUrlIos = encodeURIComponent(obj.iosDownloadUrl);
            const redirectUrlWinows = encodeURIComponent(obj.windowsDownloadUrl);
 
            androidDownloadUrl.value = `https://track.tenjin.com/v0/click/hgCIBTPU8WHHLmiLJ5HPbD?tenjin_parameter_0=${fbp}&tenjin_parameter_1=${fbc}&redirect_url=${redirectUrlAndroid}`;
            iosDownloadUrl.value = `https://track.tenjin.com/v0/click/c5tOz1lg06RDxCgSpFx5xu?tenjin_parameter_0=${fbp}&tenjin_parameter_1=${fbc}&redirect_url=${redirectUrlIos}`;
            windowsDownloadUrl.value = `https://track.tenjin.com/v0/click/hgCIBTPU8WHHLmiLJ5HPbD?tenjin_parameter_0=${fbp}&tenjin_parameter_1=${fbc}&redirect_url=${redirectUrlWinows}`
        }



    }

    function checkClient(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent)) {
            isAndroid.value = true;
            isIOS.value = false;
            isOther.value = false;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            isIOS.value = true;
            isAndroid.value = false;
            isOther.value = false;
        } else {
            isOther.value = true;
            isAndroid.value = false;
            isIOS.value = false;
        }
    }

    //format name
    function formatDayOrNum(goods: Goods) {
        if (goodsIndex.value == 2) {
            return goods.count + t("message.sheets");
        }
        else {
            return goods.days + t("message.days");
        }
    }
    function formatDayVipName(days: undefined | number) {
        if (days == undefined) {
            return t("message.monthly_vip");
        }
        switch (days) {
            case 0:
            case 30:
                return t("message.monthly_vip");
            case 90:
                return t("message.quarterly_vip");
            case 365:
                return t("message.yearly_vip");
            default:
                return t("message.trial_vip");
        }
    }
    function handleWindowsDownload() {
        downloadRef.value?.showModal();
    }







    return {
        isLoading,
        isMenuVisible,
        isShowLoginView,
        ticketCount,
        vipTime,
        goodsIndex,
        currentList,
        goodsList,
        selectIds,
        isAndroid,
        isIOS,
        isOther,
        qrLink,
        androidDownloadUrl,
        iosDownloadUrl,
        windowsDownloadUrl,
        email,
        expirationTime,
        payRef,
        downloadRef,
        handleWindowsDownload,
        getLoginQrCode,
        loginOrLogout,
        getHwid,
        switchLocale,
        toggleMenu,
        cancelLogin,
        checkAndLogin,
        isLogined,
        getPackages,
        updateCurrentList,
        getUserInfo,
        checkClient,
        formatDayOrNum,
        formatDayVipName,
        toBuyGoodsByButton,
        getResourceUrls,
        getLocalTips,
        setFacebookCookies
    };













}


