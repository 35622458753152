declare global {
    interface Window {
        Intercom: any;
        intercomSettings: any;
    }
}

class IntercomService {
    private appId: string;
    constructor(appId: string) {

        this.appId = appId;
    }

    load() {
        (() => { // 使用箭头函数以保留 `this` 上下文
            const w = window as any;
            const ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                const d = document;
                // 定义 i 函数为箭头函数
                const i = (...args: any[]) => {
                    i.c(args);
                };
                i.q = [["", null]] as [string, any][]; 
                i.q.length = 0; // 立即清空数组，只是为了类型推断
                i.c = function (args: any) {
                    i.q.push(args);
                };
    
                w.Intercom = i;

                const l = () => { // 使用箭头函数
                    const s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = `https://widget.intercom.io/widget/${this.appId}`;
                    const x = d.getElementsByTagName('script')[0];
                    if (x && x.parentNode) {
                        x.parentNode.insertBefore(s, x);
                    } else {
                        d.head.appendChild(s); // 使用 `document.head` 直接访问
                    }
                };
                if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
    }
    setUser(id?: string, email?: string, name?: string) {
        window.Intercom('boot', {
            app_id: this.appId,
            user_id: id, 
            email: email, // 用户的电子邮件地址
            name: name, // 可选：用户的全名
        });
    }

    // 可选：如果用户登出，你可能需要重置 Intercom 状态
    logout() {
        window.Intercom('shutdown');
    }
}

export const intercomService = new IntercomService("buap18n4");
