// Import Vue, the App component, and additional libraries with TypeScript syntax
import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import i18n from '@/locale/i18n';
import packageInfo from '../package.json'
import { useEncryptedFieldsStorage } from '../src/plugins/encryptedFieldsStorage';


// Create and mount the Vue application with TypeScript syntax
const app = createApp(App);
const pinia = createPinia();
console.log(`App version: ${packageInfo.version}`);
app.use(i18n);
app.use(pinia)
const listenerStore = useEncryptedFieldsStorage();
listenerStore.initializeListeners();

app.mount('#app');
