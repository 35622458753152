export default {
    message: {
        language: "Language",
        en: "English",
        zh_hk: "Traditional Chinese",
        home: 'Homepage',
        download: 'Download',
        purchase: 'Purchase',
        contact: 'Contact',
        customer: 'Customer Support',
        login: "Login",
        logout: "Logout",
        enjoy: "Enjoy a free Internet world!",
        enjoy_title: "The Hiven VPN provides you with an ideal solution that can break the network restrictions and allow you to enjoy the free and open Internet. With the Hiven VPN, you can easily climb over the wall and enjoy the seamless Internet experience wherever you are. Why not take this tool now? Download the Hiven VPN now and explore the Web without restrictions!",
        choose_platform: "Choose your device platform",
        check_out: "Checkout Our Hiven VPN",
        faster_safer: "Faster and safer wall climbing software",
        trial: "Trial",
        member: "Member",
        ticket: "Ticket",
        trial_vip: "Trial VIP",
        days: "days",
        sheets: "sheets",
        monthly_vip: "Monthly VIP",
        quarterly_vip: "Quarterly VIP",
        yearly_vip: "Yearly VIP",
        unlimited_bandwidth: "unlimited bandwidth",
        emali_support: "24/7 email support",
        customer_support: "24-hour customer service:",
        product_desc: "The Hiven VPN is a top tier product that Hiven HK Limited. takes pride in.",
        quick_links: "Quick Links",
        contact_info: "Contact Information",
        ticket_count: "Ticket Quantity: ",
        vaild_date: "Valid Until: ",
        expired_on: "Expired on: ",
        be_member: "Welcome，become a member now",
        scan_code: "Scan the QR code below to log in to the webpage",
        expiration_time: "Expiration time:",
        refresh: "Refresh",
        cancel_login: "Cancel Login",
        place_order: "Place Order",
        processing: "Processing...",
        windows_download_tip:"If the file - ladder.exe got removed Microsoft Defender (common issues for newly released application), please open your Microsoft Defender to allowlist this program manually. ",
        close:"Close",
        download_tip:"Download Tip"

    },
};

