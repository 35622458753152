import { createI18n } from 'vue-i18n';
import en from './en';
import hk from './hk';

// 定义本地化消息类型
type MessageSchema = typeof en;

const i18n = createI18n<[MessageSchema], 'en' | 'zh-hk'>({
    locale: 'zh-hk', // Set the default locale
    legacy: false, // Specify the composition API mode
    messages: {
        en,
        'zh-hk': hk,
    }
});

export default i18n;
