<template>
    <div class="modal fade" ref="downloadRef" tabindex="-1" aria-labelledby="downloadModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="downloadModalLabel">{{ $t("message.download_tip") }}</h5>

                </div>
                <div class="modal-body">
                    <!-- 下载相关的信息或操作放在这里 -->
                    <p>{{ $t("message.windows_download_tip") }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="hideModal">{{
                        $t("message.close") }}</button>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

import { Modal } from 'bootstrap';

const downloadRef = ref<HTMLElement | null>(null);
let modalInstance: Modal | null = null;


onMounted(() => {
    modalInstance = new Modal(downloadRef.value!, { keyboard: false });
});

onUnmounted(() => {
    modalInstance?.dispose();
});

const showModal = () => {

    modalInstance?.show();
};


const hideModal = () => {

    modalInstance?.hide();
};


defineExpose({ showModal });
</script>

<style scoped>
/* 你的样式 */
</style>