// src/plugins/encryptedFieldsStorage.ts
import CryptoJS from 'crypto-js';
import { defineStore } from 'pinia';
import { useLadderStore } from './ladderStore';

const SECRET_KEY = process.env.VUE_APP_SECRET_KEY as string;

const encrypt = (data: string): string => CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();

const decrypt = (data: string): string => {
    const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const useEncryptedFieldsStorage = defineStore('listener', {
    actions: {
        initializeListeners() {
            const store = useLadderStore();
            // 加载并解密每个字段
            if (!store.$state) return;
            Object.keys(store.$state).forEach(key => {
                const encryptedData = localStorage.getItem(`${store.$id}-${key}`);
                if (encryptedData) {
                    try {
                        // 注意：这里的操作直接修改了store的状态，这在实践中可能需要更精细的处理方式
                        store.$patch({ [key]: decrypt(encryptedData) });
                    } catch (error) {
                        console.error(`Failed to decrypt the state for ${key}:`, error);
                    }
                }
            });
            // 监听状态变化并加密存储每个字段
            store.$subscribe((mutation, state) => {
                Object.keys(state).forEach(key => {
                    const encryptedData = encrypt(state[key]);
                    localStorage.setItem(`${store.$id}-${key}`, encryptedData);
                });
            });
           



        },
    },
});
